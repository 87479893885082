import React, { useState, useEffect } from 'react';
import "./MainDiv.css"
import CardUser from '../../../components/CardUser/CardUser';
import CopiLink from "../../../components/CopiLink/CopyLink"
import CardData from "../../../components/CardData/CardData"
import CardPack from '../../../components/CardPack/CardPack';
import InteresCompuesto from "../../../components/InteresCompuesto/InteresCompuesto.jsx"
import { useUser } from '../../../context/UserContext.js';

const MainDiv = () => {
    const { userData, loading } = useUser();

    useEffect(() => {
        console.log(userData)
        console.log(loading)
    }, [userData])

    return (
        <div className="seccion-main">
            {loading ? (
                <div class="spinner"></div>
            ) : (
                <section className='dashboard'>
                    <div className="sec0">|
                        <i className="bi bi-bank"></i>
                        <span>DASHBOARD</span>
                    </div>
                    <div className="item-grid sec1"><CardData dato={(userData?.walletTotal || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="TOTAL EARNINGS" /></div>
                    <div className="item-grid sec2"><CardData dato={(userData?.retiros || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="TOTAL WITHDRAWALS" /></div>
                    <div className="item-grid sec3"><CardPack dato={userData?.staterPack || 0} /></div>
                    <div className="item-grid sec5"><CardData dato={(userData?.walletCom || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="COMMISSION WALLET" /></div>
                    <div className="item-grid sec4"><CardData dato={(userData?.walletDiv || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="DIVIDEND WALLET" /></div>
                    <div className="item-grid sec6"><CardData dato={(userData?.bonoRangoRes || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="RESIDUAL RANK" /></div>
                    <div className="item-grid sec7"><CardData dato={(userData?.bonoRefDirect || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="DIRECT REFERRAL BONUS" /></div>
                    <div className="item-grid sec8"><CardData dato={(userData?.bonoIngresoRes || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="RESIDUAL INCOME FEES" /></div>
                    <div className="item-grid sec9"><CardData dato={(userData?.bonoIgualacion || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="MATCHING BONUS" /></div>
                    <div className="item-grid sec10"><CardData dato={(userData?.bonoFastTrack || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} type={false} titulo="FASTRACK BONUS" /></div>
                    <div className='item-grid sec11'><InteresCompuesto/></div>
                    <div className="item-grid sec12"><CopiLink/></div>
                    <div className="item-grid sec13"><img alt="promo" /></div>
                    <div className="item-grid sec15"><img alt="promo" /></div>
                    <div className="item-grid sec14"><CardUser/></div>
                </section>
            )}
        </div>
    )
}

export default MainDiv;